import React from "react"
import Text from "../../atoms/text"
import Button from "../../atoms/button"
import TextGroup from "../../molecules/text-group"

import "./property-toolbar.scss"

function PropertyToolbar(props) {
  const propertyName = <Text text={props.Name} font="font_style__field"></Text>
  const propertyAddress = (
    <Text text={props.Address} color="color__slate-gray"></Text>
  )

  const formatter = new Intl.NumberFormat(undefined, {
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const formattedPrice = formatter.format(props.Price)

  const propertyPriceDesc =
    props.CalcPrice.nights === undefined ? (
      <Text text="Starting at" font="font_style__field"></Text>
    ) : (
      <Text text="Suggested price" font="font_style__field"></Text>
    )

  const propertyPriceValue =
    props.CalcPrice.nights === undefined ? (
      <React.Fragment>
        <Text
          text={"USD " + formattedPrice}
          font="font_style__field"
          color="color__cerulean-blue"
          spaced="4"
        ></Text>
        <Text
          text="per night"
          font="font_style__fancy"
          color="color__slate-gray"
        ></Text>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Text
          text={"USD " + props.CalcPrice.totalPrice}
          font="font_style__field"
          color="color__cerulean-blue"
          spaced="4"
        ></Text>
        <Text
          text={`for ${props.CalcPrice.nights} nights`}
          font="font_style__fancy"
          color="color__slate-gray"
        ></Text>
      </React.Fragment>
    )

  return (
    <div className="toolbar">
      <div className="toolbar__description">
        <TextGroup
          className="toolbar__property_name"
          firstLine={propertyName}
          secondLine={propertyAddress}
          separator="line"
        ></TextGroup>
        <TextGroup
          className="toolbar__property_value"
          firstLine={propertyPriceDesc}
          secondLine={propertyPriceValue}
        ></TextGroup>
      </div>
      <div className="toolbar__book_button">
        <Button
          text="Contact Owner"
          styleColor="main"
          onClickEvent={props.showBookForm}
        ></Button>
      </div>
    </div>
  )
}
export default PropertyToolbar
