import React from "react"
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api"
import "./map.scss"

function Map(props) {
  const styles = require("./MapStyles.json")

  const position = {
    lat: props.lat,
    lng: props.lng,
  }

  return (
    <LoadScriptNext
      googleMapsApiKey={props.apiKey}
      onError={error => {
        console.log(error)
      }}
    >
      <GoogleMap
        mapContainerClassName={props.mapClass}
        zoom={props.zoom}
        center={position}
        options={{
          mapTypeId: "hybrid",
          mapTypeControl: false,
          streetViewControl: false,
          styles: styles,
        }}
      >
        <Marker position={position} />
      </GoogleMap>
    </LoadScriptNext>
  )
}
export default Map
