//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React, { useEffect } from "react"
import ClampLines from "react-clamp-lines"
import Text from "../../atoms/text"
import Button from "../../atoms/button"
import TextGroup from "../../molecules/text-group"
import "./property-detail.scss"

function PropertyDetail(props) {
  const propertyDescription = props.Description
  const propertyTitle = (
    <Text text={props.Name} font="font_style__heading-1"></Text>
  )
  const propertyAddress = (
    <Text text={props.Address} font="font_style__heading-3"></Text>
  )

  // const propertyWishlist = (
  //   <Button text='Wishlist' icon='favorite' onlyIcon={true}></Button>
  // );

  // const propertyShare = (
  //   <Button text='Share' icon='share' onlyIcon={true}></Button>
  // );

  const propertyGuests =
    props.Guests !== 0 ? (
      <Text text={props.Guests + " guests"} icon="people_alt"></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyBeds =
    props.Beds !== 0 ? (
      <Text text={props.Beds + " bedrooms"} icon="king_bed"></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyRooms =
    props.Rooms !== 0 ? (
      <Text text={props.Rooms + " rooms"} icon="meeting_room"></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  const propertyBaths =
    props.Bathrooms !== 0 ? (
      <Text text={props.Bathrooms + " bathrooms"} icon="bathtub"></Text>
    ) : (
      <React.Fragment></React.Fragment>
    )

  useEffect(() => {
    ;[...document.getElementsByClassName("clamp-lines__button")].forEach(e =>
      e.addEventListener(
        "click",
        event => {
          event.target.parentElement.scrollIntoView({
            behavior: "smooth",
          })
        },
        true
      )
    )
  })

  return (
    <div className="detail" id={props.DetailSectionId}>
      <div className="detail__info">
        <div className="detail__title">
          <TextGroup
            className="detail__title_text"
            firstLine={propertyTitle}
            secondLine={propertyAddress}
          ></TextGroup>
          {/* <TextGroup
            className='detail__title_social'
            firstLine={propertyWishlist}
            secondLine={propertyShare}
          ></TextGroup> */}
        </div>
        <div className="detail__equip">
          {propertyGuests}
          {propertyBeds}
          {propertyRooms}
          {propertyBaths}
        </div>
        <ClampLines
          text={propertyDescription.replace(/\\n/g, "\r\n")}
          id="property__description"
          lines={4}
          ellipsis="..."
          className="detail__description"
          innerElement="p"
        />
      </div>
      <div className="detail__cta">
        <div className="detail__cta_info">
          <Text text="Starting at" font="font_style__heading-4"></Text>
          <TextGroup
            className="detail__cta_value"
            firstLine={
              <Text
                text={"USD " + props.Price}
                font="font_style__heading-2"
                color="color__cerulean-blue"
              ></Text>
            }
            secondLine={<Text text="Per night" font="font_style__fancy"></Text>}
          ></TextGroup>
        </div>
        <div className="detail__cta_button">
          <Button
            text="Contact Owner"
            styleColor="main"
            onClickEvent={props.showBookForm}
          ></Button>
        </div>
      </div>
    </div>
  )
}
export default PropertyDetail
