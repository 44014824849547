//React Imports
import React from "react"
import Text from "../../atoms/text"
//Styles
import "./footer.scss"
//Navigation Bar
function Footer(props) {
  //Item Logo
  const LogoLink = () => (
    <a href="/">
      <img
        className="footer__logo_image"
        src="/images/navigaze.png"
        alt="Navigaze"
      ></img>
    </a>
  )

  const currentDate = new Date()
  const year = currentDate.getFullYear()

  const Copyright = () => (
    <Text text={`Copyright © ${year} Navigaze.`} color="color__white"></Text>
  )

  return (
    <footer className="footer">
      <div className="footer__logo">
        <LogoLink />
      </div>
      <div className="footer__copyright">
        <Copyright />
      </div>
    </footer>
  )
}
export default Footer
