import React from "react"
import Text from "../../atoms/text"
import Map from "../../atoms/map"
import "./property-location.scss"

function PropertyLocation(props) {
  return (
    <div className="location">
      <div className="location__title">
        <Text text="Location" font="font_style__heading-2"></Text>
      </div>
      <div className="location__map">
        <Map
          mapClass={props.mapClass}
          apiKey="AIzaSyCD6PZ0pld1Lu_-0VrNwWzMVt_fuL73KM8"
          mapTitle="Property"
          zoom={13}
          lat={props.Location.coordinates[1]}
          lng={props.Location.coordinates[0]}
        ></Map>
      </div>
    </div>
  )
}
export default PropertyLocation
