//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React from "react"
import "./iframe.scss"
function Iframe(props) {
  return (
    <div>
      <iframe {...props} title="Container"></iframe>
    </div>
  )
}
export default Iframe
