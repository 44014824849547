import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "./tabs.scss"

function TabsComponent(props) {
  const TabItems = []
  const TabPanels = []
  const { className = "" } = props

  props.tabs.forEach((tab, index) => {
    TabItems.push(<Tab key={index}>{tab.option}</Tab>)
    TabPanels.push(<TabPanel key={index}>{tab.content}</TabPanel>)
  })

  return (
    <Tabs
      className={`react-tabs ${className}`}
      forceRenderTabPanel={true}
      selectedIndex={props.tabIndex}
      onSelect={props.onSelect}
    >
      <div className="react-tabs__tab-container">
        <TabList>{TabItems}</TabList>
      </div>
      <div className="react-tabs__panel-container">{TabPanels}</div>
    </Tabs>
  )
}
export default TabsComponent
