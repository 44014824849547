import React, { useState } from "react"
import { DateRange } from "react-date-range"

import Helpers from "../../../setup/utils/helper"
import Text from "../../atoms/text"
import Button from "../../atoms/button"
import TextGroup from "../../molecules/text-group"

import "../../../../node_modules/react-date-range/dist/styles.css" // main css file
import "../../../../node_modules/react-date-range/dist/theme/default.css" // theme css file
import "./property-availability.scss"

function PropertyAvailability(props) {
  const [isDesktop, setDesktop] = useState(Helpers.viewportValidate)
  Helpers.viewportState(setDesktop)

  const calendarState = [
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]

  const [stepState, setStepState] = useState("Select your travel dates")
  const [contactState, setContactState] = useState("")

  const calendarTitle = (
    <Text text={stepState} font="font_style__heading-4"></Text>
  )

  const calendarDescription = (
    <Text
      text={
        "Please choose dates for an indication of the total trip price. The final price is to be agreed directly with the property owner."
      }
    ></Text>
  )

  const calendarOnChange = item => {
    let changed = false
    if (item.selection.startDate !== props.startDate) {
      props.setStartDate(item.selection.startDate)
      changed = true
    }

    if (item.selection.endDate !== props.endDate) {
      props.setEndDate(item.selection.endDate)
      changed = true
    }

    if (changed) {
      if (
        item.selection.startDate.getTime() === item.selection.endDate.getTime()
      ) {
        setStepState("Select your check out date")
        setContactState("")
      } else {
        const diffTime = Math.abs(
          item.selection.startDate - item.selection.endDate
        )
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        const message =
          diffDays === 1
            ? `Staying for ${diffDays} night`
            : `Staying for ${diffDays} nights`
        setStepState(message)
        setContactState(
          <div className="availability__contact_button">
            <Button
              text="Request Booking"
              styleColor="main"
              onClickEvent={props.showBookForm}
            />
          </div>
        )
      }
    }
  }

  props.customOnShownDateChange()

  return (
    <div className="availability">
      <div className="availability__title">
        <Text text="Availability" font="font_style__heading-2"></Text>
      </div>
      <TextGroup
        className="availability__description"
        firstLine={calendarTitle}
        secondLine={calendarDescription}
      ></TextGroup>

      <div className="availability__calendar">
        {typeof window !== "undefined" ? (
          <DateRange
            editableDateInputs={true}
            onChange={calendarOnChange}
            onShownDateChange={props.customOnShownDateChange}
            moveRangeOnFirstSelection={false}
            ranges={calendarState}
            months={isDesktop ? 2 : 1}
            direction="horizontal"
            monthDisplayFormat="MMMM yyyy"
            minDate={new Date()}
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() + 2))
            }
            disabledDates={props.conditionalReservedDates}
          />
        ) : (
          <React.Fragment />
        )}
        {contactState}
      </div>
    </div>
  )
}

export default PropertyAvailability
