import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "./image.scss"
function Image(props) {
  return (
    <LazyLoadImage
      src={props.src}
      alt={props.alt}
      effect="blur"
      className={props.styleClass}
      onClick={props.onClick}
    />
  )
}
export default Image
