import React, { useEffect, useState } from "react"
import Services from "../../../setup/services/services"
import { Pannellum } from "@rogopolo/navigaze-pannellum-react"

import PannellumView from "../../atoms/pannellum-view"

import "./panoramic.scss"

function Panoramic(props) {
  const [, setPanoData] = useState(null)
  const [hotspot, setHotspot] = useState([])
  const [position, setPosition] = useState(null)
  const [, setExpression] = useState(null)
  let hotspots = []
  // let hots = null
  useEffect(() => {
    try {
      if (props.PlaceId) {
        Services.get("/panoByPlaceId/" + props.PlaceId._id).then(response => {
          setPosition(props.CameraPosition)
          const hotss = [
            <Pannellum.Hotspot
              type={"custom"}
              pitch={parseFloat(props.CameraPosition.pitch)}
              yaw={parseFloat(props.CameraPosition.yaw)}
              text="Prop"
              URL={"#"}
              cssClass={"custom-hotspot"}
              handleClick={(evt, args) => props.onHotspotClicked(1)}
            />,
          ]
          // hots = hotss
          setHotspot(hotss)
        })

        Services.get("/pano/" + props.PlaceId._id).then(response => {
          if (response !== undefined) {
            setExpression(
              response.data.story.scenes[0].media.source.pattern
                .replace("{face}", "%s")
                .replace("{level}", "%l")
                .replace("{x}", "%x")
                .replace("{y}", "%y")
                .replace(".jpg", "")
            )
            setPanoData(response.data)

            for (
              let i = 0;
              i < response.data.panoproperties.marks.length;
              i++
            ) {
              hotspots.push({
                props: {
                  pitch: parseFloat(
                    response.data.panoproperties.marks[i].PanoMarkerPosition
                      .position.theta
                  ),
                  yaw: parseFloat(
                    response.data.panoproperties.marks[i].PanoMarkerPosition
                      .position.phi
                  ),
                  roll: parseFloat(
                    response.data.panoproperties.marks[i].PanoMarkerPosition
                      .position.phi
                  ),
                  cssClass: "",
                  createTooltipFunc: hotspotFn,
                  createTooltipArgs:
                    response.data.panoproperties.marks[i].PropertyId,
                  type: "custom",
                },
              })
            }
            /* const hotss = hotspots.map((component, key) => (
            <Pannellum.Hotspot
              type='custom'
              pitch={parseFloat(component.props.pitch)}
              yaw={parseFloat(component.props.yaw)}
              text=''
              URL={component.props.createTooltipArgs}
            />
          ));
          console.log(hotss);
          hots=hotss;
          setHotspot(hotss);*/
          }
        })
      }
    } catch (Exception) {
      console.log("Panorama error")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const getHotSpots = () => {
  //   const z = hotspots.map((component, key) => (
  //     <Pannellum.Hotspot
  //       type="custom"
  //       pitch={parseFloat(component.pitch)}
  //       yaw={parseFloat(component.yaw)}
  //       text={key}
  //       handleClick={(evt, args) => console.log("error")}
  //       handleClickArg={{ name: "test" }}
  //       URL=""
  //     />
  //   ))
  //   console.log(z)
  //   return z
  // }
  const multiRes = {
    basePath: "https://res.cloudinary.com/navigaze/image/fetch/f_auto,q_auto,c_fill,g_auto/https://www.navigaze.com",
    path: "/panos/" + props.id + "/%l/%s%y_%x",
    fallbackPath: "/fallback/%s",
    extension: "jpg",
    tileResolution: 512,
    maxLevel: 5,
    cubeResolution: 7320,
  }

  function hotspotFn(hotSpotDiv, args) {
    hotSpotDiv.classList.add("custom-tooltip")
    var span = document.createElement("span")
    span.innerHTML = args
    hotSpotDiv.appendChild(span)
    span.style.width = span.scrollWidth - 20 + "px"
    span.style.marginLeft =
      -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + "px"
    span.style.marginTop = -span.scrollHeight - 12 + "px"
  }
  const mouseEventToCoords = (e, args) => {
    // console.log(e)
    // console.log(args)
  }
  return position !== null ? (
    <PannellumView
      width={"none"}
      height={"none"}
      hfov={position ? position.fov : 100}
      yaw={position ? position.yaw : 0}
      pitch={position ? position.pitch : 0}
      type={"multires"}
      multiRes={multiRes}
      autoLoad={true}
      autoRotate={-2}
      children={hotspots}
      keyboardZoom
      mouseZoom
      showControls
      showFullscreenCtrl
      showZoomCtrl
      orientationOnByDefault={true}
      hotSpotDebug={false}
      onMousedown={mouseEventToCoords}
      hotSpots={hotspot}
      onLoad={() => {
        console.log("Panorama loaded")
      }}
    ></PannellumView>
  ) : null
}
export default Panoramic
