//React imports
import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
//Internal Atoms
import Button from "../../atoms/button"
import Modal from "../../atoms/modal"
import ImageGroup from "../image-group"
//Styles
import "./photo-gallery.scss"
//Sample data

//Photo Gallery Molecule
function PhotoGallery(props) {
  const [state, setState] = useState(false)
  const showModal = () => setState(true)
  const hideModal = () => setState(false)

  const images = props.Images.map(image => {
    if (image.startsWith("/img/")) {
      return image.replace("/img/", "https://res.cloudinary.com/navigaze/image/fetch/f_auto,q_auto,c_fill,g_auto/https://www.navigaze.com/img/")
    }
    return image
  })

  const gallery = () => {
    return images
      ? images.slice(0, 6).map((image, index) => {
          const remainingImageCount = images.length - 6
          const remainingLabel =
            index === 5 && remainingImageCount > 0 ? (
              <span
                className="gallery__remaining_count"
                onClick={showModal}
                onKeyDown={event => {
                  if (event.keycode === 13) showModal()
                }}
                role="button"
                tabIndex="0"
              >
                +{remainingImageCount}
              </span>
            ) : (
              ""
            )

          return (
            <div className="gallery__preview_wrapper" key={image + index}>
              <LazyLoadImage
                effect="blur"
                src={image}
                alt={index}
                onClick={showModal}
              />
              {remainingLabel}
            </div>
          )
        })
      : null
  }

  const ImageGroupFun = () => <ImageGroup images={images}></ImageGroup>
  return (
    <React.Fragment>
      <Modal
        classId="gallery_modal"
        show={state}
        children={ImageGroupFun()}
        handleClose={() => hideModal()}
      />
      <div className="gallery">
        <div className="gallery__preview">{gallery()}</div>
        <div className="gallery__view_button">
          <Button
            text="View gallery"
            icon="photo_library"
            onClickEvent={showModal}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default PhotoGallery
