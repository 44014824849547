import React from "react"
import Image from "../../atoms/image"
import Text from "../../atoms/text"

import "./image-group.scss"

function ImageGroup(props) {
  const images = () => {
    return props.images
      ? props.images.map((image, index) => {
          return (
            <div className="image_group__section" key={index}>
              <Image src={image} alt={index} onClick={props.onClickEvent} />
            </div>
          )
        })
      : null
  }
  return (
    <div className="image_group">
      <div className="image_group__title">
        <Text text="Gallery" font="font_style__heading-2"></Text>
      </div>
      <div className="image_group__content">{images()}</div>
    </div>
  )
}
export default ImageGroup
