//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React from "react"
import Text from "../../atoms/text"

import "./property-amenities.scss"
// import Services from "../../../Setup/Services/services";

function ProperyAmenities(props) {
  const amenityGroups = props.Amenities.reduce(
    (acc, amenity, index) => {
      acc[index % 5].push(amenity.Name)
      return acc
    },
    [[], [], [], [], []]
  )
  let index2 = 1
  const amenities = () => {
    return amenityGroups
      ? amenityGroups.map(amenityGroup => {
          const amenity = amenityGroup.map(amenity => {
            index2++
            return (
              <div
                className="amenities__section_content"
                key={amenity + index2}
              >
                {amenity}
              </div>
            )
          })

          return (
            <div className="amenities__section" key={index2}>
              <div className="amenities__section_title font_style__heading-4"></div>
              {amenity}
            </div>
          )
        })
      : null
  }

  return (
    <div className="amenities">
      <div className="amenities__container">
        <div className="amenities__title">
          <Text text="Amenities & Services" font="font_style__heading-2"></Text>
        </div>
        <div className="amenities__info">{amenities()}</div>
      </div>
    </div>
  )
}
export default ProperyAmenities
