import React from "react"
import { Accordion, AccordionItem } from "react-sanfona"
//Estilos
import "./accordion.scss"

function AccordionComponent(props) {
  const { className = "" } = props

  const AccItems = () => {
    return props.items.map((item, index) => {
      return (
        <AccordionItem
          titleTag="button"
          key={"accordion-item-" + index}
          title={item.title}
          expanded={props.expanded}
        >
          {item.content}
        </AccordionItem>
      )
    })
  }
  return (
    <Accordion className={`accordion ${className}`}>{AccItems()}</Accordion>
  )
}
export default AccordionComponent
