//React imports
import React from "react"
import * as Yup from "yup"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

//import ReCAPTCHA from 'react-google-recaptcha';
import Services from "../../../setup/services/services"
import Helpers from "../../../setup/utils/helper"
import PropertyHelpers from "../../../setup/utils/property-helper"

import Text from "../../atoms/text"
import DynamicForms from "../../molecules/dynamic-forms"
import Loading from "../../atoms/loading"

//Styles
import "../../../../node_modules/react-phone-number-input/style.css"
import "./booking-form.scss"

function BookingForm(props) {
  const swal = withReactContent(Swal)

  const initialValues = {
    adults: 1,
    kids: 0,
    firstName: "",
    lastName: "",
    email: "",
    emailConf: "",
    message: "",
    phone: "",
    date: [props.startDate, props.endDate],
  }

  const priceValues = PropertyHelpers.calcPrices(
    props.startDate,
    props.endDate,
    props.propertyData.PricedDates,
    props.propertyData.Price
  )

  const fieldGroupDates = {
    title: "Your travel dates",
    fieldGroup: [
      [
        {
          type: "daterange",
          name: "date",
          label: "Dates",
          title: "Dates",
          className: "booking_form__range_picker",
          customOnChange: (startDate, endDate) => {
            props.setStartDate(startDate)
            props.setEndDate(endDate)
          },
          config: { onShownDateChange: props.customOnShownDateChange },
          reservedDates: props.conditionalReservedDates,
        },
      ],
    ],
  }

  const fieldGroupParty = {
    title: "Your party",
    description: `This property allows a maximum of ${props.propertyData.Sleeps} guests`,
    fieldGroup: [
      [
        {
          type: "guest",
          name: "adults",
          label: "Adults",
          title: "Adults*",
          parentClassName: "booking_form__adults",
        },
        {
          type: "guest",
          name: "kids",
          label: "Kids",
          title: "Kids",
          parentClassName: "booking_form__kids",
        },
      ],
    ],
  }

  const fieldGroupPrice =
    priceValues.averagePrice !== "0.00"
      ? {
          title: "Price indication",
          description: "Prices to be agreed directly with the property owner.",
          fieldGroup: [
            [
              {
                type: "dynamicPrice",
                title: "Suggested price per night",
                label: "USD " + priceValues.averagePrice,
                className: "booking_form__row_3",
              },
              {
                type: "dynamicPrice",
                title: "Nights",
                label: priceValues.nights,
                className: "booking_form__row_1",
              },
              {
                type: "dynamicPrice",
                title: "Suggested total for your stay",
                label: "USD " + priceValues.totalPrice,
                className: "booking_form__row_6",
              },
            ],
          ],
        }
      : null

  const formDefinition = {
    fieldGroups: [
      fieldGroupDates,
      fieldGroupParty,
      fieldGroupInformation,
      fieldGroupPrice,
      fieldGroupAdditional,
    ].filter(x => x !== null),
    submitClickCallback: values => {
      swal.fire({
        html: (
          <React.Fragment>
            <Loading />
            <h4>Sending...</h4>
          </React.Fragment>
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
      })

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      }

      const dates = values.date.map(date =>
        date.toLocaleDateString(undefined, options)
      )

      Services.sendInBlue(
        1,
        [{ email: props.propertyData.OwnerEmail ?? "booking@navigaze.com" }],
        // [{ email: "booking@navigaze.com" }],
        [
          {
            email: values.email,
            name: `${values.firstName} ${values.lastName}`,
          },
        ],
        {
          // property owner
          OwnerFirstname: props.propertyData.OwnerContact,
          OwnerLastname: null,
          OwnerEmail: props.propertyData.OwnerEmail,

          // user
          UserFirstname: values.firstName,
          UserLastname: values.lastName,
          UserEmail: values.email,
          UserPhone: values.phone,
          UserAdults: values.adults,
          UserKids: values.kids,
          DateArrival: dates[0],
          DateDeparture: dates[1],
          Comments: values.message,

          // property
          PropertyName: props.propertyData.PropertyName,
        }
      )
        .then(() => {
          return swal
            .fire({
              title: "Thank you!",
              text: "Your inquiry was sent successfully.",
              html: "",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: true,
            })
            .then(() => {
              return props.handleClose()
            })
        })
        .catch(err => {
          swal.fire({
            title: "Oops",
            text: "Something went wrong " + err,
            html: "",
            icon: "error",
            allowOutsideClick: true,
            showConfirmButton: true,
          })
        })
    },
    numbered: true,
  }

  const validationSchema = Yup.object().shape({
    adults: Yup.number()
      .min(1, "At least 1 adult is required")
      .test(
        "max",
        `Total of guests must not exceed ${props.propertyData.Sleeps}`,
        function (value) {
          return value <= props.propertyData.Sleeps - this.parent.kids
        }
      )
      .integer("The number must be an integer")
      .required("Required"),
    kids: Yup.number()
      .test(
        "max",
        `Total of guests must not exceed ${props.propertyData.Sleeps}`,
        function (value) {
          return value <= props.propertyData.Sleeps - this.parent.adults
        }
      )
      .integer("The number must be an integer")
      .required("Required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .matches(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/, "Invalid name")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .matches(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/, "Invalid name")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email field is required"),
    emailConf: Yup.string()
      .test(
        "equals",
        `Email and email confirmation must be the same`,
        function (value) {
          return value === this.parent.email
        }
      )
      .email("Invalid email")
      .required("Email confirmation field is required"),
  })

  const validate = async values => {
    const Options = {
      strict: false,
      abortEarly: false,
      stripUnknown: false,
      recursive: true,
    }

    let errors = {}
    errors = await validationSchema
      .validate(values, Options)
      .catch(function (err) {
        return Helpers.yupValidate(values, err)
      })

    //Date validation TODO
    if (!values.date) errors.date = "Required"

    if (errors === values) return {}
    return errors
  }

  props.customOnShownDateChange()

  return (
    <div className="booking_form">
      <div className="booking_form__title">
        <Text
          text="Contact property owner directly"
          font="font_style__heading-2"
        ></Text>
      </div>
      <DynamicForms
        initialValues={initialValues}
        className="booking_form__form"
        formDefinition={formDefinition}
        formClass="booking_form"
        submitName="Send request"
        validate={values => validate(values)}
      />
    </div>
  )
}

const fieldGroupInformation = {
  title: "Your information",
  fieldGroup: [
    [
      {
        type: "text",
        name: "firstName",
        label: "First name",
        title: "Name*",
        className: "field_first_name",
      },
      {
        type: "text",
        name: "lastName",
        label: "Last name",
        title: "Last name*",
        className: "field_last_name",
      },
    ],
    [
      {
        type: "text",
        name: "email",
        label: "Email",
        title: "Email*",
        className: "field_email",
      },
    ],
    [
      {
        type: "text",
        name: "emailConf",
        label: "Email confirmation",
        title: "Email confirmation*",
        className: "field_email",
      },
    ],
    [
      {
        type: "phone",
        name: "phone",
        label: "Phone",
        title: "Phone",
        className: "field_phone",
      },
    ],
  ],
}

const fieldGroupAdditional = {
  title: "Additional information",
  fieldGroup: [
    [
      {
        type: "textarea",
        name: "message",
        label: "Message",
        title: "Anything else we need to know?",
        className: "field_message",
        placeholder: "Enter message",
      },
    ],
  ],
}

export default BookingForm
