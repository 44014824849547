import React from "react"
import "./button.scss"

function Button(props) {
  const {
    text,
    styleColor = false,
    icon = false,
    onlyIcon = false,
    onClickEvent,
  } = props

  const iconStyle = onlyIcon ? "icon_only" : ""
  const iconElement = icon ? (
    <i className={`material-icons-outlined ${iconStyle}`} aria-label={text}>
      {icon}
    </i>
  ) : (
    ""
  )

  const textElement = !onlyIcon ? (
    <React.Fragment>
      {iconElement}
      <span>{text}</span>
    </React.Fragment>
  ) : (
    iconElement
  )

  const customStyle = styleColor ? `button--${styleColor}` : ""
  return (
    <button
      type="button"
      className={`button font_style__body ${customStyle} ${iconStyle}`}
      onClick={onClickEvent}
    >
      {textElement}
    </button>
  )
}
export default Button
