import React from "react"
import Text from "../../atoms/text"
import TextGroup from "../../molecules/text-group"
import AccordionComponent from "../../molecules/accordion"

import "./property-policies.scss"

function PropertyPolicies(props) {
  const accordionItems = []
  let index = 0
  if (Array.isArray(props.Rules) && props.Rules.length) {
    const houseRules = props.Rules.map(rule => {
      index++
      return (
        <TextGroup
          className="policies__content"
          key={index}
          firstLine={
            <Text text={rule.Name} font="font_style__heading-4"></Text>
          }
          secondLine={<Text text={rule.Description}></Text>}
        ></TextGroup>
      )
    })

    accordionItems.push({ title: "House rules", content: houseRules })
  }

  if (Array.isArray(props.Policies) && props.Policies.length) {
    const policies = props.Policies.map(policy => {
      index++
      return (
        <TextGroup
          className="policies__content"
          key={index}
          firstLine={
            <Text text={policy.Name} font="font_style__heading-4"></Text>
          }
          secondLine={<Text text={policy.Description}></Text>}
        ></TextGroup>
      )
    })

    accordionItems.push({ title: "Policies", content: policies })
  }

  if (Array.isArray(props.Terms) && props.Terms.length) {
    const termsAndConditions = props.Terms.map(term => {
      index++
      return (
        <TextGroup
          className="policies__content"
          key={index}
          firstLine={
            <Text text={term.Name} font="font_style__heading-4"></Text>
          }
          secondLine={<Text text={term.Description}></Text>}
        ></TextGroup>
      )
    })

    accordionItems.push({
      title: "Terms and conditions",
      content: termsAndConditions,
    })
  }

  return (
    <div className="policies">
      <div className="policies__title">
        <Text text="Policies" font="font_style__heading-2"></Text>
      </div>
      <AccordionComponent
        items={accordionItems}
        expanded={false}
        className="policies__accordion"
      ></AccordionComponent>
    </div>
  )
}
export default PropertyPolicies
