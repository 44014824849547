import React from 'react';
import { Pannellum } from '@rogopolo/navigaze-pannellum-react';
function PannellumView(props) {
 return(<Pannellum
      width={'none'}
      height={'none'}
      hfov={props.hfov}
      yaw={props.yaw}
      pitch={props.pitch}
      type={props.type}
      multiRes={props.multiRes}
      autoLoad={props.autoLoad}
      autoRotate={props.autoRotate}
      keyboardZoom
      mouseZoom
      showControls
      showFullscreenCtrl
      showZoomCtrl
      hotSpotDebug={props.hotSpotDebug}
      onMousedown={props.mouseEventToCoords}
      onLoad={()=>props.onLoad()}
    >
     {props.hotSpots??null}
    </Pannellum>)
}
export default PannellumView;