//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React, { useState } from "react"
import {
  GoogleMap,
  StreetViewPanorama,
  StreetViewService,
  LoadScriptNext,
} from "@react-google-maps/api"
import { document } from "browser-monads"

import Text from "../../atoms/text"
import Iframe from "../../atoms/iframe"
import TabsComponent from "../../molecules/tabs"
import Panoramic from "../../molecules/panoramic"

import "./property-view.scss"

function PropertyView(props) {
  const [streetValidated, setStreetValidated] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [tabs, setTabs] = useState([
    neighborhoodView(props),
    propertyTour(props),
    details(props),
  ])

  const onTabSelect = index => typeof tabs[index].onSelect !== "undefined" ? tabs[index].onSelect() : setTabIndex(index);

  const streetViewCenter = {
    lng: props.location[0],
    lat: props.location[1],
  }

  const onLoad = streetViewService => {
    streetViewService.getPanorama(
      {
        location: streetViewCenter,
        radius: 50,
        source: "outdoor",
      },
      (data, status) => {
        if (status === "OK") {
          const heading = bearing(
            {
              lat: data.location.latLng.lat(),
              lng: data.location.latLng.lng(),
            },
            streetViewCenter
          )
          setTabs([
            neighborhoodView(props, setTabIndex),
            streetView(props, data.location.latLng, heading),
            propertyTour(props),
            details(props),
          ])
        }
        setStreetValidated(true)
      }
    )
  }

  return (
    <React.Fragment>
      {streetValidated === true ? (
        <TabsComponent
          className="property_view"
          tabs={tabs}
          tabIndex={tabIndex}
          onSelect={onTabSelect}
        ></TabsComponent>
      ) : (
        <LoadScriptNext
          googleMapsApiKey="AIzaSyCD6PZ0pld1Lu_-0VrNwWzMVt_fuL73KM8"
          onError={error => {
            console.log(error)
          }}
        >
          <StreetViewService onLoad={onLoad} />
        </LoadScriptNext>
      )}
    </React.Fragment>
  )
}

const neighborhoodView = (props, setTabIndex) => ({
  option: (
    <Text
      text="Neighborhood View"
      color="color__parent"
      font="font_style__in-field"
      icon="vrpano"
    ></Text>
  ),
  content: (
    <div>
      <Panoramic
        id={props.id}
        PlaceId={props.PlaceId}
        CameraPosition={props.CameraPosition}
        onHotspotClicked={index => setTabIndex(index)}
      />
    </div>
  ),
  forceRender: false,
})

const propertyTour = props => ({
  option: (
    <Text
      text="Property Tour"
      color="color__parent"
      font="font_style__in-field"
      icon="360"
    ></Text>
  ),
  content: (
    <Iframe
      className="property_view__iframe_full"
      src={props.VRUrl}
      allowFullScreen={true}
    ></Iframe>
  ),
  forceRender: true,
})

const streetView = (props, center, heading) => ({
  option: (
    <Text
      text="Street View"
      color="color__parent"
      font="font_style__in-field"
      icon="streetview"
    ></Text>
  ),
  content: (
    <GoogleMap
      zoom={7}
      center={center}
      mapContainerClassName="property_view__iframe_small"
    >
      <StreetViewPanorama
        position={center}
        visible={true}
        pov={{ heading: heading, pitch: 0 }}
      />
    </GoogleMap>
  ),
  forceRender: false,
})

const details = (props) => ({
  option: (
    <Text
      text="Details"
      color="color__parent"
      font="font_style__in-field"
      icon="info"
    ></Text>
  ),
  onSelect: () => {
    document.getElementById(props.DetailSectionId).scrollIntoView();
    return false;
  },
  forceRender: false,
})

// Calculates the bearing between two coordinates
const bearing = (
  { lat: startLat, lng: startLng },
  { lat: destLat, lng: destLng }
) => {
  // Converts from degrees to radians.
  const toRadians = degrees => (degrees * Math.PI) / 180

  // Converts from radians to degrees.
  const toDegrees = radians => (radians * 180) / Math.PI

  startLat = toRadians(startLat)
  startLng = toRadians(startLng)
  destLat = toRadians(destLat)
  destLng = toRadians(destLng)

  const y = Math.sin(destLng - startLng) * Math.cos(destLat)
  const x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
  let brng = Math.atan2(y, x)
  brng = toDegrees(brng)
  return (brng + 360) % 360
}

export default PropertyView
