import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { parseISO, eachDayOfInterval, addDays, subDays } from "date-fns"
import * as queryString from "query-string"
import "../../translations/i18n"
import { addBackToTop } from "vanilla-back-to-top"
import { document } from "browser-monads"

import Services from "../../setup/services/services"
import PropertyHelpers from "../../setup/utils/property-helper"
// import useSiteMetadata from "../../setup/hooks/use-site-metadata"

import NavBar from "../../components/organisms/navbar"
import PropertyToolBar from "../../components/organisms/property-toolbar"
import PropertyView from "../../components/organisms/property-view"
import ProperyDetail from "../../components/organisms/property-detail"
import PhotoGallery from "../../components/molecules/photo-gallery"
import PropertyAmenities from "../../components/organisms/property-amenities"
import PropertyLocation from "../../components/organisms/property-location"
import PropertyAvailability from "../../components/organisms/property-availability"
import PropertyPolicies from "../../components/organisms/property-policies"
import Blank from "../../components/organisms/blank"
import Footer from "../../components/organisms/footer"
import Modal from "../../components/atoms/modal"

import BookingForm from "../../components/organisms/booking-form"
import ContactForm from "../../components/organisms/contact-form"
import ListHomeForm from "../../components/organisms/list-home-form"

import "./property.scss"

function Property(props) {
  if (typeof window !== `undefined`) {
    addBackToTop({
      innerHTML:
        '<i class="material-icons-outlined icon_only" aria-label="Back to top">arrow_upward</i>',
    })
  }

  // Init states for booking modal
  const [bookingState, setBookingState] = useState(false)
  const showBookingModal = () => setBookingState(true)
  const hideBookingModal = () => setBookingState(false)

  // Init states for list modal
  const [listState, setListState] = useState(false)
  const showListModal = () => setListState(true)
  const hideListModal = () => setListState(false)

  // Init states for contact modal
  const [contactState, setContactState] = useState(false)
  const showContactModal = () => setContactState(true)
  const hideContactModal = () => setContactState(false)

  // Set the links to open a form in a modal
  const link = [
    { id: "listHome", modal: showListModal },
    { id: "contactUs", modal: showContactModal },
  ]

  const propertyData = props.pageContext

  propertyData.PricedDates = PropertyHelpers.defaultPrices(
    propertyData.Price,
    propertyData.PricedDates
  )

  const [reservedDates, setReservedDates] = useState(
    props.pageContext.ReservedDates
  )

  const processedReservedDates = getReservedDates(reservedDates)

  const detailsRef = "detailSection";

  const name = props.uri.split("/")[3]

  useEffect(() => {
    // Get the property information
    const nameArray = name.split("-")
    const id = nameArray[nameArray.length - 1]

    Services.get("/property/" + id).then(response => {
      if (
        response !== undefined &&
        typeof response.data.ReservedDates !== "undefined"
      ) {
        setReservedDates(response.data.ReservedDates)
      }
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    checkIn: checkInString,
    checkOut: checkOOutString,
  } = queryString.parse(props.location.search)

  let checkIn = parseISO(checkInString)
  let checkOut = parseISO(checkOOutString)

  if (
    isNaN(checkIn.getTime()) ||
    isNaN(checkOut.getTime()) ||
    checkIn.getTime() > checkOut.getTime()
  ) {
    checkIn = new Date()
    checkOut = new Date()
  }

  const [startDate, setStartDate] = useState(checkIn)
  const [endDate, setEndDate] = useState(checkOut)

  const calcPrice = PropertyHelpers.calcPrices(
    startDate,
    endDate,
    propertyData !== null ? propertyData.PricedDates : "[]",
    propertyData !== null ? propertyData.Price : "0.00"
  )

  const showPropertyPage = () =>
    propertyData !== null ? (
      <React.Fragment>
        <Helmet>
          <title>Navigaze - {propertyData.PropertyName}</title>
          <meta
            name="title"
            content={`Navigaze - ${propertyData.PropertyName}`}
          />
          <meta name="description" content={propertyData.Description} />
          <meta
            property="og:title"
            content={`Navigaze - ${propertyData.PropertyName}`}
          />
          <meta property="og:description" content={propertyData.Description} />
          <meta
            property="og:image"
            content={`https://www.navigaze.com${propertyData.VRFrontThumbnail[0]}`}
          />
          <meta
            property="og:url"
            content={`https://www.navigaze.com${props.location.pathname}`}
          />
        </Helmet>
        <PropertyToolBar
          Name={propertyData.PropertyName}
          Address={propertyData.PlaceId?.Name}
          Price={propertyData.Price}
          showBookForm={() => showBookingModal()}
          CalcPrice={calcPrice}
        />
        <PropertyView
          VRUrl={propertyData.VRUrl}
          location={propertyData.MapLocation.coordinates}
          CameraPosition={propertyData.CameraPosition}
          id={propertyData._id}
          PlaceId={propertyData.PlaceId}
          DetailSectionId={detailsRef}
        ></PropertyView>
        <ProperyDetail
          DetailSectionId={detailsRef}
          Name={propertyData.PropertyName}
          Description={propertyData.Description}
          Address={propertyData.PlaceId?.Name}
          Guests={propertyData.Sleeps}
          Beds={propertyData.Bedrooms}
          Rooms={0}
          Bathrooms={propertyData.Bathrooms}
          Price={propertyData.Price}
          showBookForm={showBookingModal}
        />
        <PhotoGallery Images={propertyData.VRFrontThumbnail} />
        <PropertyAvailability
          conditionalReservedDates={processedReservedDates}
          showBookForm={showBookingModal}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          customOnShownDateChange={setEdgeDates}
        ></PropertyAvailability>
        {propertyData.amenities !== null ? (
          <PropertyAmenities
            Amenities={propertyData.amenities}
          ></PropertyAmenities>
        ) : (
          <React.Fragment />
        )}
        <PropertyLocation
          mapClass="map__container"
          Location={propertyData.MapLocation}
        ></PropertyLocation>
        {propertyData.cancelpolicies !== null ? (
          <PropertyPolicies
            Policies={propertyData.cancelpolicies}
            Rules={propertyData.houserules}
          ></PropertyPolicies>
        ) : (
          <React.Fragment />
        )}
        <Modal
          classId="booking_form_modal"
          show={bookingState}
          children={
            bookingState ? (
              <BookingForm
                propertyData={propertyData}
                conditionalReservedDates={processedReservedDates}
                customOnShownDateChange={setEdgeDates}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleClose={() => hideBookingModal()}
              />
            ) : (
              <React.Fragment />
            )
          }
          handleClose={() => hideBookingModal()}
        />
        <Modal
          classId="contact_form_modal"
          show={contactState}
          children={
            contactState ? (
              <ContactForm handleClose={() => hideContactModal()} />
            ) : (
              <React.Fragment />
            )
          }
          handleClose={() => hideContactModal()}
        />
        <Modal
          classId="list_home_form_modal"
          show={listState}
          children={
            listState ? (
              <ListHomeForm handleClose={() => hideListModal()} />
            ) : (
              <React.Fragment />
            )
          }
          handleClose={() => hideListModal()}
        />
      </React.Fragment>
    ) : (
      <Blank />
    )

  return (
    <React.Fragment>
      <NavBar links={link} />
      {showPropertyPage()}
      <Footer />
    </React.Fragment>
  )
}

const getReservedDates = reservedDates => {
  const today = new Date()
  const dateRangesRaw = JSON.parse(reservedDates)

  dateRangesRaw.sort((a, b) => parseISO(a[0]) - parseISO(b[0]))

  const reserved = dateRangesRaw
    .filter(dateRange => parseISO(dateRange[1]) >= today)
    .reduce((acc, dateRange, index, dateRanges) => {
      if (dateRanges[index - 1] && dateRange[0] === dateRanges[index - 1][1]) {
        acc[acc.length - 1][1] = dateRange[1]
        return acc
      }

      return [...acc, dateRange]
    }, [])
    .reduce(
      (acc, dateRange) => [
        ...acc,
        ...eachDayOfInterval(
          parseISO(dateRange[0]).getTime() ===
            subDays(parseISO(dateRange[1]), 1).getTime()
            ? {
                start: parseISO(dateRange[0]),
                end: subDays(parseISO(dateRange[1]), 1),
              }
            : {
                start: addDays(parseISO(dateRange[0]), 1),
                end: subDays(parseISO(dateRange[1]), 1),
              }
        ),
      ],
      []
    )

  return reserved
}

const setEdgeDates = () => {
  setTimeout(function () {
    const days = document.getElementsByClassName("rdrDay")
    Array.from(days).map((day, index) => {
      const nextDay = days[index + 1]

      if (
        nextDay &&
        nextDay.classList.contains("rdrDayDisabled") &&
        !day.classList.contains("rdrDayDisabled")
      ) {
        day.setAttribute("data-tooltip", true)
        day.setAttribute("data-tooltip-position", "top")
        day.setAttribute("data-tooltip-text", "Checkout only")
      } else {
        day.removeAttribute("data-tooltip")
        day.removeAttribute("data-tooltip-position")
        day.removeAttribute("data-tooltip-text")
      }
      return true
    })
  }, 100)
}

export default Property
